import React from "react"
import ReactDOM from 'react-dom'
import Img from 'gatsby-image'
import Portal from './Portal'
import showdown from 'showdown'

class ImageContentModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            active: false
        }
        this.content = this.props.content
        this.setActive = this.setActive.bind(this)
        this.setInactive = this.setInactive.bind(this)
        this.modal = (<div></div>)
    }

    setActive() {
        this.setState({active: true})
        console.log('setActive');
    }
    setInactive() {
        this.setState({active: false})
        console.log('setInactive');
}

    render() {
        const converter = new showdown.Converter({
        strikethrough: true
        })

        let imageContent = converter.makeHtml(this.content);
        this.modal = (
                // <div className={`modal ${this.state.active ? 'is-active': ''}`}>
                <div>
                <div className={"modal"+` ${this.state.active ? 'is-active' : ''}`}>
                    {/* {console.log(this.state)} */}
                    <div onClick={this.setInactive} className="modal-background"></div>
                    <div className="modal-content" style={{maxWidth: '700px'}}>
                        <section class="modal-card-body">
                            <Img  {...this.props} />
                        </section>
                        <section class="modal-card-body">
                            <div className="content" dangerouslySetInnerHTML={{__html: imageContent}}></div>
                        </section>
                    </div>
                    <button onClick={this.setInactive} className="modal-close is-large" aria-label="close"></button>
                </div>
                </div>
        )
        return (
            <div>
            <div className="photo_grid_item" onClick={this.setActive}>
                <Img  {...this.props} />
            </div>
            <Portal>
                {this.modal}
            </Portal>
            </div>
        )
    } 
}

export default ImageContentModal