import React from "react"
import { graphql } from "gatsby"
import Helmet from 'react-helmet'
import Img from "gatsby-image/withIEPolyfill"
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import Layout from "../components/layout"
import ImageContentModal from "../components/ImageContentModal"
import ImageModal from "../components/ImageModal"
import SEO from "../components/seo"
import Icon from "../components/icon"
import ClientOnly from '../components/ClientOnly'
import arrow from "../images/arrow-dn.svg"
import dash from "../images/dash.png"
import slugify from "../components/slugify"
import showdown from 'showdown'

const IndexPage = ({data}) => {
  let page = data.graphAPI.homepage
  const converter = new showdown.Converter({
      strikethrough: true
  })
  let missionContent = converter.makeHtml(page.missionContent);
  return (
  <Layout>
    <Helmet>
      <meta property='og:title'  content={page.title} />
      <meta name='twitter:title' content={page.title} />
      
      <link rel='canonical'       href="https://sugarlandlegacy.org" />
      <meta property='og:url'  content="https://sugarlandlegacy.org" />
      <meta name='twitter:url' content="https://sugarlandlegacy.org" />

      <meta name='description'         content="We are a multi-purpose 501(c)3 working to leave a legacy by enhancing Sugar Land’s quality of life. We work with individuals, corporations, trusts, and other foundations interested in making sustainable, charitable, tax deductible donations to financially support or facilitate support for activities and capital projects that benefit the Sugar Land community." />
      <meta property='og:description'  content="We are a multi-purpose 501(c)3 working to leave a legacy by enhancing Sugar Land’s quality of life. We work with individuals, corporations, trusts, and other foundations interested in making sustainable, charitable, tax deductible donations to financially support or facilitate support for activities and capital projects that benefit the Sugar Land community." />
      <meta name='twitter:description' content="We are a multi-purpose 501(c)3 working to leave a legacy by enhancing Sugar Land’s quality of life. We work with individuals, corporations, trusts, and other foundations interested in making sustainable, charitable, tax deductible donations to financially support or facilitate support for activities and capital projects that benefit the Sugar Land community." />

      <meta property="og:image"  content={'https://sugarlandlegacy.org'+data.social.publicURL} />
      
      <meta property='og:site_name' content='Sugar Land Legacy Foundation' />
      <meta property='og:type' content='website' />
    </Helmet>
    <SEO title="Home" />
    <div className="container anchor">
      <Img className="home-right-float" fixed={data.monument.childImageSharp.fixed} />
      {/* <div className="home-right-caption">
        Leaving a Legacy in Sugar Land
      </div> */}
      <div className="home-left-copy">
        <span><img width="40" style={{paddingBottom: 4, marginRight: '0.5rem'}} src={dash} alt=""></img> ABOUT SLLF</span>
        <div className="indented">
          <h1 className="is-size-1" style={{ marginTop: 0}}>{page.title}</h1>
          <div dangerouslySetInnerHTML={{__html: page.content}}></div>
          <p className="secondary bold" style={{marginTop: '1.5rem'}}>Scroll for more <img width="20" src={arrow} alt=""></img></p>
        </div>
      </div>
    </div>
    <div className="banner">
      <div className="container">
        <div className="columned">
          <div className="column is-6 is-offset-3 has-text-centered">
            <h1 className="is-size-1" style={{ marginTop: 0}}>{page.missionTitle}</h1>
            <div className="mission-content" dangerouslySetInnerHTML={{__html: missionContent}}></div>
            <div className="is-size-1 low-contrast" style={{marginTop: '-200px', pointerEvents: 'none'}}>
              <Icon style={{ display: 'inline-block', width: '200px', height: '250px' }}></Icon>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div className="jump container">
      { page.section.map((section, index) => {
        let sectionContent = converter.makeHtml(section.body);
        if(index % 2) {
          return (
          <div key={index} style={{margin: '3rem 0'}}className="columns">
            <div className="column is-6 is-offset-1 inset">
              <h1 className="is-size-1" style={{ marginTop: 0}}>{section.title}</h1>
              <div dangerouslySetInnerHTML={{__html: sectionContent}}></div>
            </div>
            <div className="column is-4">
              <Img style={{width: '100%', marginTop: '3rem'}} fluid={section.image.documentFile.childImageSharp.fluid} />
            </div>
          </div>
        )
      } else {
          return (
          <div key={index} style={{margin: '3rem 0'}}className="columns">
            <div className="column is-4 is-offset-1">
              <Img style={{width: '100%', marginTop: '3rem'}} fluid={section.image.documentFile.childImageSharp.fluid} />
            </div>
            <div className="column is-6 inset">
              <h1 className="is-size-1" style={{ marginTop: 0}}>{section.title}</h1>
              <div dangerouslySetInnerHTML={{__html: sectionContent}}></div>
            </div>
          </div>
        )

      }
    })}
    </div>
    <div id="success" className="jump container">
      <h1  className="big-text has-text-centered" style={{ marginTop: 0}}>{page.galleryTitle}</h1>
      <p className="has-text-centered">Select photo for details.</p>
      <ClientOnly>
        <div className="photo_grid">
          { page.gallery.map(entry => (
            <ImageContentModal content={entry.description} key={entry.id} style={{width: '100%'}} fluid={entry.image.documentFile.childImageSharp.fluid} />
          ))}
        </div>
      </ClientOnly>
    </div>
    <div id="opportunities" className="jump dark end">
      <ParallaxProvider>
        <div className="jump container swing-content">
          <h1 className="big-text has-text-centered" style={{ marginTop: 0}}>{page.projectsTitle}</h1>
          { page.project.map((project, index) => {
            let projectContent = converter.makeHtml(project.content);
            return (
            <div id={"opportunities-"+slugify(project.title)} key={project.id} className="jump container swing-item">
              <div className={`${project.featuredHasText === true ? "uncover-this" : "cover-this"} ${project.featuredImage.documentFile.childImageSharp.fluid.aspectRatio < 1 ? "tall" : ""}`}>
                <Img className="content-image" fluid={project.featuredImage.documentFile.childImageSharp.fluid} />
              </div>

              <Parallax className="content-card-container" y={index % 2 === 0 ? ['-30px','30px'] : ['30px','-30px']} >
              <div className={`swing-this ${project.featuredImage.documentFile.childImageSharp.fluid.aspectRatio < 1 ? "with-tall" : ""}`}>
              <div className="content-card">
                <a href={"/opportunities/"+slugify(project.title)}><h2 className="is-size-2">{project.title}</h2></a>
                <div dangerouslySetInnerHTML={{__html: projectContent}}></div>
                <ClientOnly>
                  { project.additionalImages.length > 0 ? (
                    <div className="columns" style={{marginTop: '3rem'}}>
                      { project.additionalImages.map(image => (
                        <div key={image.documentFile.id} className="column is-6">
                            <ImageModal fluid={image.documentFile.childImageSharp.fluid} />
                          { image.caption !== '' ? (
                            <div dangerouslySetInnerHTML={{__html: image.caption}}></div>
                          ): (<></>)}
                        </div>
                      ))}
                    </div>
                  ) : (<></>)}
                </ClientOnly>
              </div>
              </div>
              </Parallax>
              <div className="jump-gap"></div>
            </div>
          )})}
        </div>
      </ParallaxProvider>
    </div>
  </Layout>
)}

export default IndexPage

export const indexQuery = graphql`
  query {
    monument: file(relativePath: {eq: "monument-park.png"}) {
      childImageSharp {
        fixed(width: 1000){
          ...GatsbyImageSharpFixed_tracedSVG
          aspectRatio
        }
      }
    }
    social: file(relativePath: {eq: "monument-park.png"}) {
      publicURL
    }
    graphAPI {
      homepage {
        title
        content
        missionTitle
        missionContent
        section {
          title
          body
          image {
            alternativeText
            caption
            url
            updated_at
            created_at
            documentFile {
              childImageSharp {
                fluid(maxWidth: 1000){
                  ...GatsbyImageSharpFluid_tracedSVG
                  aspectRatio
                }
              }
            }
          }
        }
        galleryTitle
        gallery {
          id
          title
          description
          image {
            alternativeText
            caption
            created_at
            updated_at
            url
            documentFile {
              childImageSharp {
                fluid(maxWidth: 1000){
                  ...GatsbyImageSharpFluid_tracedSVG
                  aspectRatio
                }
              }
            }
          }
        }
        projectsTitle
        project {
          id
          title
          excerpt
          content
          featuredHasText
          featuredImage {
            url
            created_at
            updated_at
            documentFile {
              childImageSharp {
                fluid(maxWidth: 1116){
                  ...GatsbyImageSharpFluid_tracedSVG
                  aspectRatio
                }
              }
            }
          }
          additionalImages {
            url
            updated_at
            created_at
            documentFile {
              childImageSharp {
                fluid(maxWidth: 2000){
                  ...GatsbyImageSharpFluid_tracedSVG
                  aspectRatio
                }
              }
            }
          }
        }
      }
    }
  }
`